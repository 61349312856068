export const CatService_EN_Trans = {
  title: "Fiesta Catering Concepts",
  subtitle:
    "It is a long-established fact that a reader will be distracted by readable content",
  btn: "Show More",
  cateringData: {
    card1: "Street Food Festivals",
    desc1: `“Experience the ultimate flavor trip!”
At our street food festivals, your city becomes a hotspot for street food lovers. Colorful food trucks, exotic aromas, and a variety of treats leave nothing to be desired! A feast for the senses and an event that brings people together!
`,
    card2: "Corporate Events",
    desc2: `“An unforgettable day for your team!”
Whether as a thank you for your employees, an anniversary celebration, or a product launch – our food trucks make your corporate event a highlight! Let your team enjoy and be motivated together – because everything tastes better together!
`,
    card3: "Private Parties",
    desc3: `“Celebrate the way you like it!”
Weddings, birthdays, or Christmas – special occasions deserve special enjoyment. This makes your celebration a culinary experience that excites everyone and creates lasting memories!
`,
  },
  company: {
    title: "Our Top Caterers",
    view: "View",
    subtitle:
      "Favorites of our community! These caterers have been highly praised by our customers for their outstanding service and creative delicacies.",
  },
};



export const CatService_DE_Trans = {
  title: "Fiesta Catering Konzepte",
  subtitle:
    "Es ist eine lang etablierte Tatsache, dass ein Leser von Lesbarem abgelenkt wird",
  btn: "Mehr anzeigen",
  cateringData: {
    card1: "Streetfood festivals",
    desc1: `„Erleben Sie den ultimativen Geschmackstrip!“
Auf unseren Streetfoodfestivals wird Ihre Stadt zum Hotspot für Streetfood-Liebhaber. Bunte Foodtrucks, exotische Aromen und eine Vielfalt an Leckereien lassen keinen Wunsch offen! Ein Fest für die Sinne und ein Event, das zusammenbringt!
`,
    card2: "Firmen Events",
    desc2: `„Ein unvergesslicher Tag für Ihr Team!“
Ob als Dankeschön für Ihre Mitarbeiter, zum Firmenjubiläum oder zur Produkteinführung – mit unseren Foodtrucks wird Ihr Firmenevent zum Highlight! Lassen Sie Ihr Team gemeinsam genießen und motivieren – denn zusammen schmeckt’s einfach besser!
`,
    card3: "Private Feiern",
    desc3: `„Feiern Sie, wie es Ihnen schmeckt!“
Hochzeiten, Geburtstage oder Weihnachten – besondere Anlässe verdienen besonderen Genuss. So wird Ihre Feier zum kulinarischen Erlebnis, das alle begeistert und an das man sich gern erinnert!
`,
  },
  company: {
    title: "Unsere top Caterer",
    view: "Ansicht",
    subtitle:
      "Die Favoriten unserer Community! Diese Caterer wurden von unseren Kunden für ihren herausragenden Service und ihre kreativen Köstlichkeiten besonders gelobt.",
  },
};

