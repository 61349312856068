export const Chat_EN_Trans = {
    noData:'No data',
    now:'Now',
    m:'m',
    h:'h',
    d:'d',
    unsend:"Unsend",
    cancel:'Cancel',
    typeMsg: 'Type message here...',
    selectChat:"Please select chat to continue",
    loading:"Loading..",
    today:"Today",
    yesterday:"Yesterday"
};
export const Chat_DE_Trans = {
    noData: 'Keine Daten',
    now: 'Jetzt',
    m: 'Min',
    h: 'Std',
    d: 'Tg',
    unsend: "Nicht senden",
    cancel: 'Abbrechen',
    typeMsg: 'Nachricht hier eingeben...',
    selectChat: "Bitte Chat auswählen, um fortzufahren",
    loading: "Wird geladen..",
    today:"Heute",
    yesterday:"Gestern"
};