export const Explore_En_trans = {
  title: "Discover Caterer",
  subtitle: `Search for a suitable caterer for your event and browse through our selection of certified partners.`,
  edit: "Edit",
  more: "Show Me More",
  button: "Book Now",
  moreDetails: "More Details",
  foodtruckdetails: "Caterer Details",
  foodtruck: "Caterer",
  fiestaCatering: "Fiesta Catering",
  home: "Home",
  foodcategories: "Food Categories",
  certificate: "Certificate",
  clientreviews: "Client Reviews",
  address: "Address",
  postalcode: "Postal Code",
  waterEle: "Water & Electricity available",
  waterGas: "Water & Gas available",
  Ele: "Electricity available",
  Gas: "Gas available",
  meal: "Meals",
  size: "Size in meters",
  power: "Power",
  sendmessage: "Send a message",
  myreviews: "My Reviews",
  leaveyourreviews: "Leave your review",
  reviewPlc: "Write your review here",
  equipment: "Equipment required on site",
  submit: "Submit",
};


export const Explore_DE_Trans = {
  title: "Caterer entdecken",
  subtitle: `Suche selbst nach einem passenden Caterer für dein Event und klicke dich durch unser Angebot an zertifizierten Partnern.`,
  edit: "Bearbeiten",
  more: "Zeig Mir Mehr",
  button: "Jetzt buchen",
  moreDetails: "Mehr Details",
  equipment: "Erforderliche Ausrüstung vor Ort",
  foodtruckdetails: "Details zum Caterer",
  foodtruck: "Caterer",
  fiestaCatering: "Fiesta Catering",
  home: "Startseite",
  water: "Wasser und Strom verfügbar",
  meal: "Verpflegung",
  foodcategories: "Essens Kategorien",
  certificate: "Zertifikat",
  clientreviews: "Kundenbewertungen",
  address: "Adresse",
  postalcode: "Postleitzahl",
  waterEle: "Wasser & Strom verfügbar",
  waterGas: "Wasser & Gas verfügbar",
  Ele: "Strom verfügbar",
  Gas: "Gas verfügbar",
  size: "Größe in Metern",
  power: "Leistung",
  sendmessage: "Nachricht senden",
  myreviews: "Meine Bewertungen",
  leaveyourreviews: "Hinterlassen Sie Ihre Bewertung",
  reviewPlc: "Schreiben Sie hier Ihre Bewertung",
  submit: "Einreichen",
};


