import { useEffect, useState } from "react";
import { API_GET } from "../API/config";
import { API_endpoints } from "../API/API_Endpoints";

const useMealsList = () => {
    const [mealList, setmealList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchList = async () => {
        try
        {
            let res = await API_GET(API_endpoints.getmealcat);
            if (res?.s)
            {
                setmealList(res?.r);
            } else
            {
                // throw new Error("Failed to fetch data");
                console.log(error)
            }
        } catch (error)
        {
            setError(error.message);
        } finally
        {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchList();
    }, []);

    return { mealList, loading, error };
};

export default useMealsList;
