import { Checkout_DE_Trans } from "../Component/ChechoutModal/CheckOut_Trans.jsx";
import { footer_DE_trans } from "../Component/Footer/Footer_trans";
import { ConfirmationModal_DE_trans } from "../Component/Modal/ConfirmModal_trans.jsx";
import { Navbar_DE_Trans } from "../Component/Navbar/Nav_Trans";
import { response_DE_trans } from "../Component/responseMsg.jsx";
import { AboutUS_DE_trans } from "../Pages/AboutUs/About_trans";
import { Login_DE_Trans } from "../Pages/Auth/Login/Login_trans";
import { Register_DE_Trans } from "../Pages/Auth/Register/Register_trans";
import { BecomeAPartner_DE_Trans } from "../Pages/BecomeAPartner/BecomeAPartner_Trans";
import { Chat_DE_Trans } from "../Pages/Chat/chat_trans.jsx";
// import { CateringList_DE_Trans } from "../Pages/CateringListScreen/CateringList_trans";
import { Faq_DE_trans } from "../Pages/FAQ/FAQ_trans.jsx";
import { FoodTruckScreen_DE_Trans } from "../Pages/FoodTruck/FoodTruck_Trans.jsx";
import { BookNow_DE_trans } from "../Pages/FoodTruckDetails/BookNow/BookNow_trans";
import { FoodTruckEdit_DE_Trans } from "../Pages/FoodTruckEdit/FoodTrucEdit_trans.jsx";
import { About_DE_trans } from "../Pages/HomePage/HomeTrans/About_trans";
import { CatService_DE_Trans } from "../Pages/HomePage/HomeTrans/CatService_trans";
import { Eventandcat_DE_Trans } from "../Pages/HomePage/HomeTrans/EvenetAndcat_trans";
import { Explore_DE_Trans } from "../Pages/HomePage/HomeTrans/Explore_trans";
import { Home_DE_Trans } from "../Pages/HomePage/HomeTrans/Home_Trans";
import { Inquiry_DE_Trans } from "../Pages/HomePage/HomeTrans/InquiryForm_Trans.jsx";
import { Menu_DE_trans } from "../Pages/HomePage/HomeTrans/Menu_trans";
import { Search_DE_Trans } from "../Pages/HomePage/HomeTrans/Search_Trans";
import { Subscription_DE_Trans } from "../Pages/HomePage/HomeTrans/Subscription_trans";
import { Imprint_DE_Trans } from "../Pages/Imprint/Imprint_trans.jsx";
import { myCompanyDetailsScreen_DE_Trans } from "../Pages/MyBusiness/MyCompanyDetailsScreen_trans";
import { Addtruck_DE_Trans } from "../Pages/MyFoodTruck/AddTruckScreen_trans.jsx";
import { Privacy_DE_Trans } from "../Pages/PrivacyPolicy/Privacy_trans";
import { ReachUs_DE_trans } from "../Pages/ReachUs/ReachUs_trans";
import { TandC_DE_Trans } from "../Pages/TermsAndConditions/TermsAndConditions_Trans";
import { OwnerProfile_DE_trans } from "../Pages/TruckOwnerProfile/OwnerProfil_trans";
import { MyBooking_DE_trans } from "../Pages/User/UserTrans/MyBooking_trans";
import { MyProfile_DE_trans } from "../Pages/User/UserTrans/MyProfile_trans";

export const German = {
  translation: {
    Login: Login_DE_Trans,
    Register: Register_DE_Trans,
    Navbar: Navbar_DE_Trans,
    section1: Home_DE_Trans,
    search: Search_DE_Trans,
    explore: Explore_DE_Trans,
    catSer: CatService_DE_Trans,
    eAndC: Eventandcat_DE_Trans,
    becomePartner: BecomeAPartner_DE_Trans,
    myProfile: MyProfile_DE_trans,
    myBooking: MyBooking_DE_trans,
    footer: footer_DE_trans,
    About: About_DE_trans,
    bookNowForm: BookNow_DE_trans,
    contact: ReachUs_DE_trans,
    faq: Faq_DE_trans,
    about: AboutUS_DE_trans,
    Menu: Menu_DE_trans,
    subscription: Subscription_DE_Trans,
    ownerProfile: OwnerProfile_DE_trans,
    privacy: Privacy_DE_Trans,
    TC: TandC_DE_Trans,
    // cateringList: CateringList_DE_Trans,
    myCompDetails: myCompanyDetailsScreen_DE_Trans,
    addtruck: Addtruck_DE_Trans,
    foodTruck: FoodTruckScreen_DE_Trans,
    imprint: Imprint_DE_Trans,
    cModal: ConfirmationModal_DE_trans,
    inquiry: Inquiry_DE_Trans,
    editTruck: FoodTruckEdit_DE_Trans,
    res: response_DE_trans,
    checkout: Checkout_DE_Trans,
    chat: Chat_DE_Trans
  },
};
