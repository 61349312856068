export const ReachUs_EN_trans = {
    contactUs: "Contact Us",
    fullname: 'Enter full name',
    email: 'Enter email',
    subject: 'Enter subject',
    desc: 'Enter description',
    number: "Enter number",
    btn:"Submit",
    fiesta:"FIESTA CATERING",
}

export const ReachUs_DE_trans = {
    contactUs: "Kontaktiere uns",
    fullname: 'Vollständigen Namen eingeben',
    email: 'E-Mail eingeben',
    subject: 'Betreff eingeben',
    desc: 'Beschreibung eingeben',
    number: "Nummer eingeben",
    btn: "Absenden",
    fiesta: "FIESTA CATERING",
};




