export const BecomeAPartner_EN_Trans = {
  title1: "Become a Partner",
  title2: "Register your company",
  prev: "Previous",
  next: "Next",
  faq: "Would you like to know more? Then click here!",
  step1: {
    title: "1. Company Details",
    company_name: "Enter catering company name",
    city: "Enter city name",
    post_Code: "Enter postal code",
    weblink: "Enter your website link",
    pl_company_name: "Enter company name",
    pl_city: "Enter city name",
    pl_post_Code: "Enter postal code",
    pl_weblink: "Enter weblink",
    state: 'Enter the state',
    state_ph: "Select state",
    vali: {
      Required: "Required",
      company_name: "Invalid company name",
      city: "Invalid city name",
      post_Code: "Invalid postal code",
      weblink: "Invalid weblink",
      InvalidURL: "Invalid URL ",
    },
  },
  step2: {
    title: "2. What types of caterers?",
    types: {
      title: "What types of caterers?",
      type1: "Bussiness Caterer",
      type2: "Food Truck",
      type3: "Barista / Bartender",
      type4: "Other",
    },
    yourSelf: {
      title: "More about yourself",
      pl: "About yourself",
      required: "Required",
    },
    socialTitle: "Social Media Links",
    foodTruckDetails: {
      title: "Food Truck Details",
      wide: "Germany-wide",
      image: {
        title: "Upload Food Truck Featured Image",
        drag: "Click here to upload image or Drag & Drop",
      },

      category: {
        title: "Choose your food concept",
        pl: "Food truck concept",
      },
      zipCode: {
        title: "Zip Code",
        pl: "Enter zip code",
      },
      area: {
        title: "Radius work area",
        pl: "Enter radius area",
      },
      desc: {
        title: "Enter Description",
        pl: "Enter description",
      },
      truckName: {
        title: "Food Truck Name",
        pl: "Enter food truck name",
      },
      address: {
        title: "Food Truck Address",
        pl: "Enter food truck address",
      },
      specification: {
        title: "Food Truck Specification",
        pl: "Enter food truck specification",
      },
      state: {
        title: "State",
        pl: "Enter state name",
      },
      time: {
        title: "Select Timing",
        pl1: "Opening hour",
        pl2: "Closing hour",
      },
    },
    questions: {
      title: "Additional Questions",
      truckSize: {
        title: " Size of the food truck in meter",
        pl: "Enter size of the food truck(meter)",
      },
      electricity: {
        title: "Required equipment on site",
        pl: "Equipment",
        other: "Other",
      },
      operatingMode: {
        title: "The operating mode of the truck",
        op1: "Kwh",
        op2: "GAS",
      },
      water: {
        title: "Is a water connection required?",
        op1: "Yes",
        op2: "No",
      },
    },
  },
  step3: {
    title: "3. Meals & Diet",
    meals: {
      title: "Categorical classification of meals",
      1: "Pizza C Pasta",
      2: "Burger C BBQ",
      3: "Seafood",
      4: "African",
      5: "Mexican",
      6: "Coffee",
      7: "Asian C BBQ",
      8: "Asian C Sushi",
      9: "Arabic",
      10: "Indian",
      11: "Cocktails C Drinks",
      12: "Other",
    },
    spDiet: {
      title: "Do you offer a special diet?",
      1: "Vegetarian",
      2: "Vegan",
      3: "Gluten-free",
    },
    Atonemeal: "At least one meal must be selected.",
    Atonediet: "At least one diet must be selected.",
  },
  step4: {
    title: "4. Image upload",
    image: {
      title: "Upload Company Image",
      note: "3 images required",
    },
    certi: {
      title: "Upload Essential Certificates",
      note: "You can upload essential certificate like Food Service Establishment Permit, Seller’s Permit, Fire Certificate etc...",
      pl: "Enter certificate name",
      Required: "Required",
    },
  },
  step5: {
    title: "5. Membership choice",
    member: {
      title: "Please select the membership which is given below.",
      6: "Monthly",
      12: "Yearly",
    },
    discount: {
      title: "Discount Code",
      pl: "Enter Discount Code",
    },
    total: "Total",
    button: "Submit",
    required: "Required",
    subscribe: "Subscribe",
    congrats: "Congratulations! You've successfully become a partner.",
    error: "Payment failed",
  },
  editModal: {
    title: "Edit Food Truck",
    save: "Update",
  },
};

export const BecomeAPartner_DE_Trans = {
  title1: "Partner werden",
  title2: "Registrieren Sie Ihr Unternehmen",
  prev: "Zurück",
  next: "Weiter",
  faq: "Would you like to know more? Then click here!",

  step1: {
    title: "1. Unternehmensdetails",
    company_name: "Geben Sie den Namen des Catering-Unternehmens ein",
    city: "Geben Sie den Stadtnamen ein",
    post_Code: "Geben Sie die Postleitzahl ein",
    weblink: "Geben Sie den Link zu Ihrer Website ein",
    pl_company_name: "Geben Sie den Firmennamen ein",
    pl_city: "Geben Sie den Stadtnamen ein",
    pl_post_Code: "Geben Sie die Postleitzahl ein",
    pl_weblink: "Geben Sie den Weblink ein",
    state: 'Geben Sie den Bundesland ein',
    state_ph: "Bundesland auswählen",
    vali: {
      Required: "Erforderlich",
      company_name: "Ungültiger Firmenname",
      city: "Ungültiger Stadtname",
      post_Code: "Ungültige Postleitzahl",
      weblink: "Ungültiger Weblink",
      InvalidURL: "Ungültige URL",
    },
  },
  step2: {
    title: "2. Welche Arten von Caterern?",
    state: 'Status eingeben',
    state_ph: "Bundesland auswählen",
    types: {
      title: "Welches Profil trifft auf dich zu?",
      type1: "Klassischer Caterer",
      type2: "Food Trucks",
      type3: "Barista/Barkeeper",
      type4: "Andere",
    },
    yourSelf: {
      title: "Mehr über Sie",
      pl: "Über Sie",
      required: "Erforderlich",
    },
    socialTitle: "Sozial Media Links",
    foodTruckDetails: {
      wide: "deutschlandweit",
      title: "Food Truck Details",
      image: {
        title: "Laden Sie ein hervorgehobenes Bild des Food Trucks hoch",
        drag: "Klicken Sie hier, um das Bild hochzuladen oder ziehen Sie es hierher",
      },
      category: {
        title: "Wählen Sie Ihr Food-Konzept",
        pl: "Food-Konzept",
      },
      zipCode: {
        title: "Postleitzahl",
        pl: "Postleitzahl eingeben",
      },
      area: {
        title: "Arbeitsradius",
        pl: "Geben Sie den Arbeitsradius ein",
      },
      desc: {
        title: "Geben Sie eine Beschreibung ein",
        pl: "Beschreibung eingeben",
      },
      truckName: {
        title: "Name des Food Trucks",
        pl: "Geben Sie den Namen des Food Trucks ein",
      },
      address: {
        title: "Adresse des Food Trucks",
        pl: "Geben Sie die Adresse des Food Trucks ein",
      },
      specification: {
        title: "Spezifikation des Food Trucks",
        pl: "Geben Sie die Spezifikation des Food Trucks ein",
      },
      state: {
        title: "Bundesland",
        pl: "Geben Sie den Namen des Bundeslandes ein",
      },
      time: {
        title: "Wählen Sie die Öffnungszeiten",
        pl1: "Öffnungszeit",
        pl2: "Schließzeit",
      },
    },
    questions: {
      title: "Zusätzliche Fragen",
      truckSize: {
        title: "Größe des Foodtrucks in Metern",
        pl: "Geben Sie die Größe des Food Trucks (Meter) ein",
      },
      electricity: {
        title: "Benötigte Ausstattung vor Ort",
        pl: "Ausstattung",
        other: "Andere",
      },
      operatingMode: {
        title: "Betriebsmodus des Trucks",
        op1: "Kwh",
        op2: "Gas",
      },
      water: {
        title: "Ist ein Wasseranschluss erforderlich?",
        op1: "Ja",
        op2: "Nein",
      },
    },
  },
  step3: {
    title: "3. Ernährungsformen",
    meals: {
      title: "Kategorische Klassifizierung der Mahlzeiten",
      1: "Pizza & Pasta",
      2: "Burger & BBQ",
      3: "Meeresfrüchte",
      4: "Afrikanisch",
      5: "Mexikanisch",
      6: "Kaffee",
      7: "Asiatisch & BBQ",
      8: "Asiatisch & Sushi",
      9: "Arabisch",
      10: "Indisch",
      11: "Cocktails & Getränke",
      12: "Andere",
    },
    spDiet: {
      title: "Bieten Sie eine Spezielle Ernährungsform an?",
      1: "Vegetarisch",
      2: "Vegan",
      3: "Glutenfrei",
    },
    Atonemeal: "Mindestens eine Mahlzeit muss ausgewählt werden.",
    Atonediet: "Mindestens eine Diät muss ausgewählt werden.",
  },
  step4: {
    title: "4. Bild hochladen",
    image: {
      title: "Unternehmensbild hochladen",
      note: "3 Bilder erforderlich",
    },
    certi: {
      title: "Laden Sie wichtige Zertifikate hoch",
      note: "Sie können wichtige Zertifikate wie Genehmigungen für Lebensmittelbetriebe, Verkäufergenehmigungen, Feuerzertifikate usw. hochladen.",
      pl: "Geben Sie den Zertifikatsnamen ein",
      Required: "Erforderlich",
    },
  },
  step5: {
    title: "5. Mitgliedschaft wählen",
    member: {
      title: "Bitte wählen Sie die unten angegebene Mitgliedschaft aus.",
      6: "Monatlich",
      12: "Jährlich",
    },
    discount: {
      title: "Rabattcode",
      pl: "Geben Sie den Rabattcode ein",
    },
    total: "Gesamt",
    button: "Einreichen",
    required: "Erforderlich",
    subscribe: "Abonnieren",
    congrats: "Herzlichen Glückwunsch! Sie sind erfolgreich Partner geworden.",
    error: "Zahlung fehlgeschlagen",
  },
  editModal: {
    title: "Food Truck bearbeiten",
    save: "Aktualisieren",
  },
};


