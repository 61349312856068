export const BookNow_EN_trans = {
  title: "Booking Form",
  required: "Required",
  city: { title: "Enter City Name", pl: "Enter City Name" },
  postal_code: { title: "Enter Postal Code", pl: "Enter Postal Code" },
  address: { title: "Enter Address", pl: "Enter Address" },
  notes: { title: "Additional Information", pl: "Additional Information" },
  catering_Type: "Choose type of meals",
  diet: "Do you require diet?",
  date: "Select Date",
  Vali_startDate: "Start date cannot be in the past",
  Vali_endDate: "End date must be after start date",
  contact: "Add contact details",
  full_name: "Enter your full name",
  email: "Enter your email",
  number: "Enter your number",
  person: "Add Persons",
  persons: "Persons",
  budgetPerPerson: "Budget per person",
  total: "Total Budget",
  button: "Book Now",
  inValid: "Invalid",
  LogInRequired: "Login required",
};

export const BookNow_DE_trans = {
  title: "Buchungsformular",
  required: "Erforderlich",
  city: { title: "Stadtname eingeben", pl: "Stadtname eingeben" },
  postal_code: { title: "Postleitzahl eingeben", pl: "Postleitzahl eingeben" },
  address: { title: "Adresse eingeben", pl: "Adresse eingeben" },
  notes: { title: "Weitere Informationen", pl: "Weitere Informationen" },
  catering_Type: "Wählen Sie die Art der Mahlzeiten",
  diet: "Benötigen Sie eine Diät?",
  date: "Datum auswählen",
  Vali_startDate: "Das Startdatum kann nicht in der Vergangenheit liegen",
  Vali_endDate: "Das Enddatum muss nach dem Startdatum liegen",
  contact: "Kontaktinformationen hinzufügen",
  full_name: "Geben Sie Ihren vollständigen Namen ein",
  email: "Geben Sie Ihre E-Mail ein",
  number: "Geben Sie Ihre Nummer ein",
  person: "Personen hinzufügen",
  persons: "Personen",
  budgetPerPerson: "Budget pro Person",
  total: "Gesamtbudget",
  button: "Jetzt buchen",
  inValid: "Ungültig",
  LogInRequired: "Anmeldung erforderlich",
};
