export const footer_EN_trans = {
  content:
    "The best food trucks and caterers for your event. Easy planning, diverse delights, unforgettable moments!",
  quick: "Quick Links",
  home: "Home",
  register: "Become a partner",
  faq: "FAQ's",
  about: "About us",
  contact: "Inquiry form",
  touch: "Contact us",
  Imprint: "Imprint",
  privacy: "Privacy Policy",
  tc: "Terms & Condition",
  newsletter: {
    title: "Subscribe to our Newsletter",
    unsubscribeTitle: "Unsubscribe from our Newsletter",
    subtitle: "Get the latest updates and offers",
    placeholder: "Enter your email",
    subscribeButton: "Subscribe",
    unsubscribeButton: "Unsubscribe",
    successMessage: "Successfully subscribed!",
    unsubscribeSuccessMessage: "Successfully unsubscribed!",
    errorMessage: "Please enter a email address.",
    invalidEmailMessage: "Please enter a valid email.",
    failureMessage: "Subscription failed. Please try again.",
    unsubscribeFailureMessage: "Unsubscription failed. Please try again.",
    generalError: "An error occurred. Please try again later.",
    switchToSubscribe: "Subscribe",
    switchToUnsubscribe: "Unsubscribe",
    customer: "Subscribe as Customer",
    company: "Subscribe as Company",
  },
};

export const footer_DE_trans = {
  content:
    "Die besten Foodtrucks und Caterer für Dein Event. Einfache Planung, vielfältiger Genuss, unvergessliche Momente!",
  quick: "Schnelle Links",
  home: "Startseite",
  register: "Partner werden",
  faq: "FAQ´s",
  about: "Über uns",
  Imprint: "Impressum",
  contact: "Anfrage stellen",
  touch: "Kontaktieren Sie uns",
  privacy: "Datenschutz",
  tc: "AGB´s",
  newsletter: {
    title: "Abonnieren Sie unseren Newsletter",
    unsubscribeTitle: "Abbestellen Sie unseren Newsletter",
    subtitle: "Erhalten Sie die neuesten Updates und Angebote",
    placeholder: "Geben Sie Ihre E-Mail-Adresse ein",
    subscribeButton: "Abonnieren",
    unsubscribeButton: "Abbestellen",
    successMessage: "Erfolgreich abonniert!",
    unsubscribeSuccessMessage: "Erfolgreich abbestellt!",
    errorMessage: "Bitte geben Sie eine E-Mail-Adresse ein.",
    invalidEmailMessage: "Bitte geben Sie ein gültiges E-Mail ein.",
    failureMessage: "Abonnement fehlgeschlagen. Bitte versuchen Sie es erneut.",
    unsubscribeFailureMessage:
      "Abbestellung fehlgeschlagen. Bitte versuchen Sie es erneut.",
    generalError:
      "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
    switchToSubscribe: "Abonnieren",
    switchToUnsubscribe: "Abbestellen",
    customer: "Als Kunde abonnieren",
    company: "Als Unternehmen abonnieren",
  },
};


