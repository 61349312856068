import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import DiasImg from "../../Assets/images/dis.webp";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { API_GET, API_POST } from "../../API/config";
import { API_endpoints } from "../../API/API_Endpoints";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import cornerImg from "../../Assets/images/Popular.webp";
import { StarSVG, TruckSVG } from "../SVG";
import CloseIcon from "@mui/icons-material/Close";
import CheckoutModal from "../ChechoutModal/CheckOutModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  p: 3,
};

export default function RenewSubsriptionModal({ setOpen, open, subType }) {
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [tabIndex, setTabIndex] = useState(1);
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [checkOutData, setCheckOutData] = useState({});

  const handleSubmit = async (data) => {
    let fiesta = localStorage.getItem("Fiesta");
    fiesta = fiesta ? JSON.parse(fiesta) : {};
    try
    {
      setLoading(data?.plan_id);
      const f = new FormData();
      f.append("user_id", fiesta?.id);
      f.append("plan_id", data?.plan_id);
      const res = await API_POST(API_endpoints.planSubscribe, f);
      if (res?.s)
      {
        const href = res?.r?.links?.find(
          (x) => x?.rel === "approve" && x?.method === "GET" && x?.href
        );
        // window.open(href?.href);
        // window.open(href?.href, "_blank", "noopener,noreferrer");
        window.location.replace(href?.href);
      }
      setLoading(null);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleDabitSubmit = async (data) => {
    let fiesta = localStorage.getItem("Fiesta");
    fiesta = fiesta ? JSON.parse(fiesta) : {};
    try
    {
      setLoading(data?.plan_id);

      const f = new FormData();
      f.append("user_id", fiesta?.id);
      f.append("plan_id", data?.plan_id);

      const res = await API_POST(
        API_endpoints.orderSubscriptionWithoutPaypal,
        f
      );
      if (res?.s)
      {
        const href = res?.r?.links?.find(
          (x) => x?.rel === "payer-action" && x?.method === "GET" && x?.href
        );
        // window.open(href?.href, "_blank", "noopener,noreferrer");
        window.location.replace(href?.href);
      }
      setLoading(null);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleCheckout = async (data) => {
    try
    {
      setCheckoutModal(true);
      setCheckOutData({ ...data, subType: tabIndex });
    } catch (error) { }
  };

  const fetchMembershipList = async () => {
    try
    {
      const res = await API_GET(API_endpoints.getSubscriptionPlanList);
      res?.s && setData(res?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMembershipList();
  }, []);

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);

  const handleTabChange = (newIndex) => {
    setTabIndex(newIndex);
  };

  const renderCards = () => {
    const filteredData =
      tabIndex === 0
        ? [data[0], data[2], data[4]]
        : [data[1], data[3], data[5]];

    return filteredData?.map((plan, index) => (
      <PlanCard
        key={index}
        data={plan}
        theme={theme}
        t={t}
        index={index}
        loading={loading === plan?.plan_id}
        i18n={i18n}
        handleCheckout={handleCheckout}
      />
    ));
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              width: {
                xs: "calc(100% - 50px)",
                md: "calc(100% - 50px)",
                lg: "fit-content",
                // xl: "calc(100% - 550px)",
              },
              height: { xs: "calc(100vh - 120px)" },
              overflow: "auto",
            }}
          >
            <Box sx={{ maxWidth: "1440px", m: "auto", py: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h5" fontWeight={600} padding="0.5rem">
                  {t("ownerProfile.payment.renewMembership")}
                </Typography>
                <Typography variant="h5" fontWeight={600} padding="0.5rem">
                  <CloseIcon
                    style={{
                      color: "grey",
                      cursor: "pointer",
                      alignSelf: "center",
                    }}
                    onClick={handleClose}
                  />
                </Typography>
              </Box>

              <Box
                sx={{
                  background: theme.palette.background.main,
                  borderRadius: "25px",
                  // padding: 3,
                  display: "flex",
                  flexDirection: "column",
                  // gap: 2,
                  // pb: 5,
                  // px: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <StarSVG /> {/* <Typography variant="h6"> */}
                  {t("becomePartner.step5.member.title")}
                  {/* </Typography> */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                    gap: 1,
                    pl: { sm: "150px" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                      my: 2,
                      boxShadow: "0px 0px 30px 0px #1A130C24",
                      px: 1,
                      py: 1,
                      borderRadius: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      // variant={tabIndex === 0 ? "contained" : "outlined"}
                      sx={{
                        background:
                          tabIndex === 0 && theme.palette.background.orange,
                        color: tabIndex === 0 ? "white" : "#000",
                        px: 2,
                        py: 1,
                        borderRadius: "30px",
                        fontWeight: tabIndex === 0 ? "400" : "500",
                        cursor: "pointer",
                        transition: "all 0.7s ease",
                      }}
                      onClick={() => handleTabChange(0)}
                    >
                      {t("becomePartner.step5.member.6")}
                      {/* Monthly */}
                    </Box>
                    <Box
                      sx={{
                        background:
                          tabIndex === 1 && theme.palette.background.orange,
                        color: tabIndex === 1 ? "white" : "#000",
                        px: 2,
                        fontWeight: tabIndex === 1 ? "400" : "500",
                        py: 1,
                        borderRadius: "30px",
                        cursor: "pointer",
                        transition: "all 0.7s ease",
                      }}
                      onClick={() => handleTabChange(1)}
                    >
                      {t("becomePartner.step5.member.12")}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <img src={DiasImg} alt="" />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: 5, md: 4 },
                    justifyContent: "start",
                    alignItems: "center",
                    pt: 3,
                    px: 1,
                    pb: 1,
                    overflow: "scroll",
                    boxSizing: "border-box",
                  }}
                >
                  {renderCards()}
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <CheckoutModal
        loading={loading}
        open={checkoutModal}
        setOpen={setCheckoutModal}
        data={checkOutData}
        handlePaypalSubmit={handleSubmit}
        handleCardSubmit={handleDabitSubmit}
      />
    </div>
  );
}

const PlanCard = ({ data, theme, t, loading, i18n, handleCheckout }) => {
  if (!data) return null;
  const background = [
    "", //for imdex management
    `linear-gradient(179.91deg, #FFF6ED 41.16%, #FFE6A5 128.42%)`,
    `linear-gradient(179.91deg, #FFF6ED 41.16%, #FFE6A5 128.42%)`,
    "linear-gradient(179.92deg, #DEE6F2 49.32%, #BADFFD 142.9%)",
    "linear-gradient(179.92deg, #DEE6F2 49.32%, #BADFFD 142.9%)",
    "linear-gradient(179.92deg, #E6FFF1 49.32%, #63FFAB 142.9%)",
    "linear-gradient(179.92deg, #E6FFF1 49.32%, #63FFAB 142.9%)",
    "linear-gradient(179.91deg, rgba(193, 234, 255, 0.4) 41.16%, #A5E1FF 128.42%)",
  ];
  const color = [
    "", //for imdex management
    "#F57C00",
    "#F57C00",
    "#164FA1",
    "#164FA1",
    "#00BF58",
    "#00BF58",
    "#00AAFF",
  ];

  return (
    <Box
      className="flexBetween"
      sx={{
        p: 3,
        width: { xs: "100%", sm: "80%", md: "333px", lg: "333px" },
        minWidth: "333px",
        background:
          data?.id === 1 || data?.id === 2
            ? background[data?.id]
            : data?.id === 3 || data?.id === 4
              ? background[data?.id]
              : (data?.id === 5 || data?.id === 6 || data?.id === 7) &&
              background[data?.id],
        borderRadius: "18px",
        position: "relative",
        flexDirection: "column",
        transition: "transform 0.4s ease, box-shadow 0.4s ease",
        // boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box sx={{}}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: "-23px",
              m: "auto",
              width: "fir-content",
              background: "#fff",
              py: 1,
              px: 2,
              border: `1px solid ${color[data?.id]}28`,
              borderRadius: "10px",
              color: color[data?.id],
              fontSize: "15px",
              boxShadow: `1px 1px 5px ${color[data?.id]}28`,
            }}
          >
            {i18n.language === "en-US" ? data?.name : data?.german_name}
          </Typography>
        </Box>
        <img
          src={cornerImg}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            right: "0",
            width: "25%",
            borderTopRightRadius: "18px",
            display: data?.id == 4 ? "block" : "none",
          }}
        />
        <Box
          sx={{
            borderBottom: `2px solid ${color[data?.id]}28`,
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            gap: 1,
            px: 1,
          }}
        >
          <Typography
            sx={{
              color: color[data?.id],
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            {data?.price}€
          </Typography>
          <Typography
            sx={{
              color: color[data?.id],
              fontSize: "18px",
              // fontWeight: "700",
            }}
          >
            {data?.id === 1 || data?.id === 3 || data?.id === 5
              ? `/ ${t("subscription.month")}`
              : `/ ${t("subscription.year")}`}
          </Typography>
        </Box>
        <Box className="flexCenter" sx={{ mt: 1.5, mb: 1 }}>
          <TruckSVG color={color[data?.id]} alt="" />
        </Box>

        <Box sx={{ py: 1, minHeight: { xs: "100px", md: "250px" } }}>
          <RenderListContent
            color={color[data?.id]}
            note={
              i18n.language === "en-US"
                ? data?.description
                : data?.german_description
            }
          />
        </Box>
      </Box>

      <Box className="flexCenter" sx={{ pt: 0.5 }}>
        <Button
          className={"fontSnugle"}
          onClick={() => !loading && handleCheckout(data)}
          type={"button"}
          disabled={loading}
          sx={{
            position: "relative",
            borderRadius: "10px",
            height: "40px",
            padding: "4px 38px",
            textTransform: "none",
            fontSize: "16px",
            overflow: "hidden",
            background: color[data?.id],
            color: theme.palette.text.defaultLight,
            zIndex: 1,
          }}
        >
          {loading == data?.plan_id ? (
            <CircularProgress style={{ color: "#f57c00" }} />
          ) : (
            t("becomePartner.step5.subscribe")
          )}
        </Button>
      </Box>
    </Box>
  );
};
const RenderListContent = ({ color, note }) => {
  return (
    <Box
      sx={{
        px: 1,
        py: 0.3,
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 0.7,
        pb: 0.5,
      }}
    >
      <Typography
        sx={{ fontSize: "14px" }}
        dangerouslySetInnerHTML={{
          __html: note,
        }}
      />
    </Box>
  );
};
