import React from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
// import useStateList from "./GetStateList";
import { useTranslation } from "react-i18next";

const StateAutocomplete = ({ formik, stateList, pagekey }) => {
  //   const { stateList } = useStateList(null);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <Box sx={{ width: "100%" }}>
      <FormControl
        fullWidth
        error={formik.touched.state_ids && Boolean(formik.errors.state_ids)}
        sx={{
          color: theme.palette.text.defaultDark,
          textAlign: "start",
          borderRadius: "12px",
        }}
      >
        <Autocomplete
          multiple
          sx={{
            borderRadius: "12px",
            "&:hover": {
              color: theme.palette.text.active,
              bgcolor: theme.palette.background.inputBg,
            },
            "&:active": {
              color: theme.palette.text.active,
              bgcolor: theme.palette.background.inputBg,
            },
          }}
          id="state_ids"
          name="state_ids"
          options={stateList || []}
          getOptionLabel={(option) =>
            i18n.language === "de" ? option.german : option.state_name
          }
          value={
            stateList?.filter(
              (m) =>
              (pagekey === 1
                ? formik.values.state_ids?.includes(m?.state_id)
                : formik.values.state_ids?.includes(m?.id))
            ) || []
          }
          onChange={(event, newValue) => {
            formik.setFieldValue(
              "state_ids",
              newValue.map((m) => (pagekey === 1 ? m?.state_id : m?.id))
            );
          }}
          onBlur={formik.handleBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                !params?.InputProps?.startAdornment?.length
                  ? t("becomePartner.step1.state_ph")
                  : null
              }
              error={
                formik.touched.state_ids && Boolean(formik.errors.state_ids)
              }
              helperText={formik.touched.state_ids && formik.errors.state_ids}
              sx={{
                "& .MuiInputBase-input": {
                  color: theme.palette.text.defaultDark,
                },
                "& .MuiInputLabel-root": {
                  color: theme.palette.text.strip,
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: theme.palette.background.strip,
                },
                "& .MuiFormHelperText-root": {
                  color: theme.palette.text.error,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "12px",
                    borderColor: `1px solid ${theme.palette.background.strip}`,
                  },
                },
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip
                key={option.id}
                label={
                  i18n?.language === "de" ? option?.german : option?.state_name
                }
                {...getTagProps({ index })}
                sx={{
                  color: theme.palette.text.defaultDark,
                  "& .MuiChip-deleteIcon": {
                    color: "black",
                  },
                }}
              />
            ))
          }
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                bgcolor: theme.palette.background.main,
                color: theme.palette.text.strip,
              }}
            >
              {children}
            </Paper>
          )}
          ListboxProps={{
            sx: {
              bgcolor: theme.palette.background.main,
              color: theme.palette.text.defaultDark,
              "& .MuiAutocomplete-option": {
                "&:hover": {
                  bgcolor: theme.palette.background.inputBgHover,
                  color: theme.palette.text.active,
                },
              },
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

export default StateAutocomplete;
