export const myCompanyDetailsScreen_EN_Trans = {
  company: "Company Name",
  city: "City Name",
  code: "Postal Code",
  link: "Web-link",
  about: "More about company",
  meals: "Types of meals",
  diets: "Special Diets",
  certificate: "Certificate",
  myTrucks: "My Food Trucks",
  add: "Add Truck",
  noData: "No Truck found",
  edit: "Edit",
  state:'State'
};

export const myCompanyDetailsScreen_DE_Trans = {
  company: "Unternehmen Name",
  city: "Stadt",
  code: "Postleitzahl",
  link: "Web-Link",
  about: "Mehr über das Unternehmen",
  meals: "Art der Mahlzeiten",
  diets: "spezielle Ernährungsformen",
  certificate: "Zertifikat",
  myTrucks: "Meine Caterer",
  add: "Truck hinzufügen",
  noData: "Kein Truck gefunden",
  edit: "Bearbeiten",
  state: 'Bundesland'
};

