import { Box, Typography, useTheme, Grid } from "@mui/material";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { useCompanyId } from "../../../../Context/CompanyIdContext";
import ButtonComponent from "../../../../Component/Button/Button";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../../Component/Navbar/NavRoutes";
import { useTranslation } from "react-i18next";
import disIcon from "../../../../Assets/images/distance.webp";
import premiumTruckLogo from "../../../../Assets/images/p2.webp";

const CatServiceCard = ({ data, index, openModal }) => {
  const theme = useTheme();
  const { companyId } = useCompanyId();
  const isOwner = data?.company_id == companyId ?? false;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation()

  const truncateCompanyName = (name, length) => {
    if (!name) return "";
    return name?.length > length ? name?.slice(0, length) + "..." : name;
  };

  const redirect = () => {
    toast.warn(t("Login.loginReq"));
    localStorage.setItem("redirectPath", location.pathname);
    navigate(NavRoutes.LOGIN);
  };

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      // className="removeDefault"
      sx={{
        boxShadow: 3,
        borderRadius: "8px",
        mt: 2,
        flexDirection: { xs: "column", sm: "row" },
        height: {
          xs: "auto",
          sm: "auto",
          md: "280px",
          lg: "260px",
        },
        overflow: "hidden",
        display: "flex",
        backgroundColor: theme.palette.background.main,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={5}
        className="removeDefault"
        sx={{
          position: "relative", // Needed for stacking context
          width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },
          height: { xs: "fit-content", sm: "fit-content", md: "100%" },
          overflow: "hidden",
          borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundImage: `url(${index === 0
            //     ? API_endpoints.baseURL + data?.food_truck_image[0]?.image
            //     : data?.image?.length > 0
            //     && API_endpoints.baseURL + data?.image[0]?.image
            //   })`,
            backgroundImage: `url(${index === 0
              ? data?.food_truck_image?.[0]?.image
                ? API_endpoints.baseURL + data.food_truck_image[0].image
                : ""
              : data?.image?.[0]?.image
                ? API_endpoints.baseURL + data.image[0].image
                : ""
              })`,

            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            filter: "blur(5px)", // Apply blur
            zIndex: 0,
          },
        }}
      >
        <img
          src={premiumTruckLogo}
          style={{
            height: "70px",
            width: "70px",
            position: "absolute",
            top: 0,
            left: 0,
            display: data?.subscription?.truck_limit == 1 ? "none" : "block",
            zIndex: 1000,
            // transform: "rotate(90deg)",
          }}
          alt=""
        />
        <img
          // src={
          //   index === 0
          //     ? API_endpoints.baseURL + data?.food_truck_image[0]?.image
          //     : data?.image?.length > 0 &&
          //       API_endpoints.baseURL + data?.image[0]?.image
          // }
          src={
            index === 0
              ? API_endpoints.baseURL +
              (data?.food_truck_image?.[0]?.image || "")
              : data?.image?.length > 0
                ? API_endpoints.baseURL + data?.image[0]?.image
                : ""
          }
          alt={data?.title || "Catering Service Image"}
          style={{
            position: "relative", // Stack on top of the blurred background
            height: "100%",
            width: "100%",
            objectFit: "contain",
            borderRadius: "inherit", // Match the parent border radius
            zIndex: 1, // Ensure it's above the blurred background
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        lg={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "space-between", md: "start" },
          alignItems: { xs: "space-between", md: "start" },
          p: { xs: 2, sm: 3, xl: 2 },
          backgroundColor: theme.palette.background.main,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: { xs: "start", md: "start" },
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 700,
              mb: 1,
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: { xs: "block", md: "none" },
            }}
          >
            {index === 0 ? data?.truck_name : data?.company_name}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 700,
              mb: 1,
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: { xs: "none", md: "block" },
            }}
          >
            {truncateCompanyName(
              index === 0 ? data?.truck_name : data?.company_name,
              20
            )}
          </Typography>

          <Typography
            sx={{
              color: theme.palette.text.darkOrange,
              fontWeight: 500,
              mb: 1,
            }}
          >
            {data?.caterer_type === 1
              ? t("becomePartner.step2.types.type1")
              : data?.caterer_type === 3
                ? t("becomePartner.step2.types.type3")
                : data?.caterer_type === 4
                  ? t("becomePartner.step2.types.type4")
                  : t("becomePartner.step2.types.type2")}
          </Typography>

          {/* Categories */}
          <Box
            sx={{
              display: index === 0 ? "flex" : "none",
              alignItems: "center",
              gap: 0.5,
              py: 0.2,
            }}
          >
            <img src={disIcon} height={18} alt="Distance Icon" />
            <Typography
              sx={{ color: theme.palette.text.active, fontWeight: "600" }}
            >
              {data?.size}
            </Typography>{" "}
            {t("myBooking.Meters")}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              height:
                index === 0
                  ? "auto"
                  : {
                    xs: "auto",
                    sm: "auto",
                    md: "70px",
                  },
              overflow: "auto",
            }}
            className="hideScrollbar"
          >
            {data?.meal?.map((x, i) => {
              return (
                <Typography
                  key={i}
                  sx={{
                    backgroundColor: theme.palette.background.label,
                    padding: "4px 8px",
                    borderRadius: "8px",
                    color: theme.palette.text.label,
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "fit-content",
                  }}
                >
                  {i18n.language === "en-US" ? x?.meal_name : x?.german}
                </Typography>
              );
            })}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            // pb: 2,
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            alignItems: "end",
            // border: "solid red",
          }}
        >
          <Box
            className="flexCenter"
            sx={{ width: { xs: "100%", sm: "80%", md: "100%" } }}
          >
            {isOwner ? (
              <ButtonComponent
                type="button"
                isAnimate={true}
                label={`${t("catSer.company.view")}`}
                onClick={() =>
                  index === 0
                    ? openModal(data)
                    : companyId
                      ? navigate(NavRoutes.MY_COMPANY)
                      : redirect()
                }
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  background: theme.palette.background.btn,
                  color: theme.palette.text.defaultLight,
                  fontSize: "16px",
                }}
              />
            ) : (
              <ButtonComponent
                type="button"
                  isAnimate={true}
                label={
                  isOwner
                    ? `${t("catSer.company.view")}`
                    : t("explore.moreDetails")
                }
                onClick={() =>
                  index === 0
                    ? navigate(`${NavRoutes.TRUCK_DETAILS}/${data?.id}`, {
                      state: { id: data?.id, company_id: data?.company_id },
                    })
                    : navigate(
                      `${NavRoutes.COMPANY_DETAILS}/${data?.company_id}`
                    )
                }
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%" },
                  background: theme.palette.background.btn,
                  color: theme.palette.text.defaultLight,
                  fontSize: "16px",
                }}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CatServiceCard;
