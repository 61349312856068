export const Login_EN_Trans = {
  register: "Register",
  login: "Login",
  email: "Enter Email",
  password: "Enter Password",
  understand: "I Understand the",
  terms: "AGB´s",
  and: "and",
  forgot: "Forgot Password?",
  privacy: " Privacy Policy.",
  already: "Already have an account?",
  donthave: "Don't have an account?",
  registerhere: "Register here",
  forgotnote:
    "Please enter your email address. You will receive a link to create a new password via email.",
  cancel: "Cancel",
  submit: "Submit",
  required: "Required",
  loginReq: "Login Required",
  approved: "Approved successfully",
  approv: "Approve",
  reject: "Rejected successfully",
  cancell: "Cancelled successfully",
  cancelled: "Cancelled",
  submitt: "Submitted successfully",
  Upload: "Upload",
  isReaded:
    "I have read and accepted the Terms and Conditions and Privacy Policy",
};


//german
export const Login_DE_Trans = {
  register: "Registrieren",
  login: "Login",
  email: "E-Mail-Adresse eingeben",
  password: "Passwort eingeben",
  understand: "Ich verstehe die",
  terms: "AGB´s",
  and: "und",
  submit :"Festlegen",
  forgot: "Passwort vergessen?",
  privacy: "Datenschutzerklärung.",
  already: "Hast du bereits ein Konto?",
  donthave: "Hast du noch kein Konto?",
  registerhere: "Hier registrieren",
  forgotnote:
    "Bitte geben Sie Ihre E-Mail-Adresse ein. Sie erhalten per E-Mail einen Link zur Erstellung eines neuen Passworts.",
  cancel: "Stornieren",
  required: "Pflichtfeld",
  loginReq: "Anmeldung erforderlich",
  approved: "Erfolgreich genehmigt",
  approv: "genehmigt",
  reject: "Erfolgreich abgelehnt",
  cancell: "Erfolgreich storniert",
  cancelled: "Storniert",
  submitt: "Erfolgreich übermittelt",
  Upload: "Hochladen",
  isReaded:
    "Ich habe die AGB´s und Datenschutzbestimmungen gelesen und zur Kenntnis genommen",
};

