export const FoodTruckEdit_EN_Trans = {
  size: "Size of the food truck in meters",
  diet: "Offer a special diet ?",
  delete: 'Delete',
  hide: "Hide",
  Visible: "Visible",
  update: "Updated successsfully"
};
export const FoodTruckEdit_DE_Trans = {
  size: "Größe des Foodtrucks in Metern",
  diet: "Bieten Sie eine spezielle Ernährungsform an ?",
  delete: 'Löschen',
  hide: "Ausblenden",
  Visible: 'Sichtbar',
  update: "Erfolgreich aktualisiert"
};
