export const OwnerProfile_EN_trans = {
  myProfile: "My Profile",
  title: "Partner Dashboard",
  dash: {
    title: "Dashboard",
    note: "Food Truck Booking Information",
    latest: "Latest Bookings",
    cards: {
      total: "Total",
      complete: "Approved",
      pending: "Pending",
      cancel: "Cancelled",
      rejected:"Rejected"
    },
    grid: {
      profile: "Profile",
      booking: "Booking Date",
      name: "Name",
      eDate: "Ending Date",
    },
  },
  profile: {
    title: "My Profile",
    note: "Profile Information",
    reset: "Reset Password",
    company: "Company Images",
    certi: "Essential Certificate",
    update: "Update",
  },
  company: {
    title: "My Company",
    note: "Company Information",
    update: "Update",
    tabs: {
      companydetails: "Company Details",
      caterertype: "Caterer Type",
      meal: "Food offering",
      images: "Images",
    },
    modal: {
      title: "Are you sure?",
      text: "Your previous food trucks will no longer be available if you proceed.",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel",
    },
  },
  payment: {
    title: "My Subscriptions",
    note: "Subscription Payment History",
    grid: {
      id: " Paypal Id",
      Image: "Image",
      status: "Status",
      subId: "Subscription Id",
      pID: "Plan ID",
      Date: "Payment Date",
      eDate: "Expiry Date",
      action: "Action",
    },
    cancelPlan: "Are you sure ?",
    cancelPlanText: "Are you sure want to cancel your subscription ?",
    cancel: "Cancel",
    cancelSub: "Cancel Subscription",
    Confirm: "Confirm",
    successTitle: "Success",
    successMessage: "Your plan has been successfully cancelled.",
    errorTitle: "Error",
    errorMessage: "There was an error cancelling your plan. Please try again.",
    verify: "Verify",
    renew: "Renew",
    renewMembership: "Renew Your Membership",
  },
  review: {
    title: "Customer Reviews",
    note: "Reviews",
    grid: {
      Profile: "Profile",
      name: "Name",
      Review: "Review",
      diplay: "Display Review",
    },
  },
  booking: {
    title: "My Orders",
    note: "Current Orders",
    grid: {
      Profile: "Profile",
      name: "User Name",
      booking: "Order Date",
      eDate: "Ending Date",
      view: "View",
      action: "Action",
      ViewDetail: "View Details",
      confirmation: 'Confirmation'
    },
    myBooking: {
      title: "My Order",
      city: "City Name",
      add: "Address",
      postal: "Postal Code",
      Type: "Type of Diets",
      meal: "Type of Meal",
      date: "Date",
      Contact: "Contact Details",
      person: "Persons",
      budget: "Budget per Person",
      amount: "Paid Amount",
    },
  },
  order: {
    title: "My Bookings",
    note: "Current Booking",
    grid: {
      Profile: "Profile",
      name: "User Name",
      booking: "Booking Date",
      eDate: "Ending Date",
      view: "View",
      action: "Action",
      ViewDetail: "View Details",
      confirmation: 'Confirmation'
    },
    myBooking: {
      title: "My Booking",
      city: "City Name",
      add: "Address",
      postal: "Postal Code",
      Type: "Type of Diets",
      meal: "Type of Meal",
      date: "Date",
      Contact: "Contact Details",
      person: "Persons",
      budget: "Budget per Person",
      amount: "Paid Amount",
    },
  },
  caterer: {
    typeofcaterer: "Type of caterer",
    moreaboutcompany: "More about Company",
  },
  meal: {
    categoryclass: "Categorical classification of meals",
    updatediet: "Update your special diet?",
  },
};

export const OwnerProfile_DE_trans = {
  myProfile: "Mein Profil",
  title: "Partner-Dashboard",
  dash: {
    title: "Dashboard",
    note: "Informationen zur Buchung von Food Trucks",
    latest: "Neueste Buchungen",
    cards: {
      total: "Gesamt",
      complete: "Genehmigt",
      pending: "Ausstehend",
      cancel: "Storniert",
      rejected:"Abgelehnt"
    },
    grid: {
      profile: "Profil",
      booking: "Buchungsdatum",
      name: "Name",
      eDate: "Enddatum",
    },
  },
  profile: {
    title: "Mein Profil",
    note: "Profilinformationen",
    reset: "Passwort zurücksetzen",
    company: "Unternehmensbilder",
    certi: "Wichtige Zertifikate",
    update: "Aktualisieren",
  },
  company: {
    title: "Mein Unternehmen",
    note: "Unternehmensinformationen",
    update: "Aktualisieren",
    tabs: {
      companydetails: "Unternehmensdetails",
      caterertype: "Caterer-Typ",
      meal: "Speiseangebot",
      images: "Bilder",
    },
    modal: {
      title: "Sind Sie sicher?",
      text: "Ihre bisherigen Food Trucks sind nicht mehr verfügbar, wenn Sie fortfahren.",
      confirmButtonText: "Ja, aktualisieren!",
      cancelButtonText: "Nein, abbrechen",
    },
  },
  payment: {
    title: "Meine Abonnements",
    note: "Zahlungshistorie des Abonnements",
    grid: {
      id: "Paypal-ID",
      Image: "Bild",
      status: "Status",
      subId: "Abonnement-ID",
      pID: "Plan-ID",
      Date: "Zahlungsdatum",
      eDate: "Ablaufdatum",
      action: "Aktion",
    },
    cancelPlan: "Sind Sie sicher?",
    cancelPlanText:
      "Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten?",
    cancel: "Abbrechen",
    cancelSub: "Abo kündigen",
    Confirm: "Bestätigen",
    successTitle: "Erfolg",
    successMessage: "Ihr Plan wurde erfolgreich gekündigt.",
    errorTitle: "Fehler",
    errorMessage:
      "Bei der Kündigung Ihres Plans ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    verify: "Überprüfen",
    renew: "Erneuern",
    renewMembership: "Erneuern Sie Ihre Mitgliedschaft",
  },
  review: {
    title: "Kundenbewertungen",
    note: "Bewertungen",
    grid: {
      Profile: "Profil",
      name: "Name",
      Review: "Bewertung",
      diplay: "Bewertung anzeigen",
    },
  },
  booking: {
    title: "Meine Bestellungen",
    note: "Aktuelle Bestellungen",
    grid: {
      Profile: "Profil",
      name: "Benutzername",
      booking: "Bestelldatum",
      eDate: "Enddatum",
      view: "Ansehen",
      action: "Aktion",
      ViewDetail: "Details ansehen",
      confirmation:'Bestätigung'
    },
    myBooking: {
      title: "Meine Bestellung",
      city: "Stadtname",
      add: "Adresse",
      postal: "Postleitzahl",
      Type: "Art der Diäten",
      meal: "Art der Mahlzeit",
      date: "Datum",
      Contact: "Kontaktdaten",
      person: "Personen",
      budget: "Budget pro Person",
      amount: "Bezahle Betrag",
    },
  },
  order: {
    title: "Meine Buchungen",
    note: "Aktuelle Buchung",
    grid: {
      Profile: "Profil",
      name: "Benutzername",
      booking: "Buchungsdatum",
      eDate: "Enddatum",
      view: "Ansehen",
      action: "Aktion",
      ViewDetail: "Details ansehen",
      confirmation: 'Bestätigung'
    },
    myBooking: {
      title: "Meine Buchung",
      city: "Stadtname",
      add: "Adresse",
      postal: "Postleitzahl",
      Type: "Art der Diäten",
      meal: "Art der Mahlzeit",
      date: "Datum",
      Contact: "Kontaktdaten",
      person: "Personen",
      budget: "Budget pro Person",
      amount: "Bezahle Betrag",
    },
  },
  caterer: {
    typeofcaterer: "Art des Caterers",
    moreaboutcompany: "Mehr über das Unternehmen",
  },
  meal: {
    categoryclass: "Kategorische Klassifizierung von Mahlzeiten",
    updatediet: "Ihre spezielle Diät aktualisieren?",
  },
};



