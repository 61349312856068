import {
  Box,
  TextField,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  Autocomplete,
  Paper,
  Chip,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import { API_GET, API_GET_OPEN } from "../../../API/config";
import { API_endpoints } from "../../../API/API_Endpoints";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Component/Loader/Loader";
import StateAutocomplete from "../../../Component/StateSelectComponent";
import useStateList from "../../../Component/GetStateList";
import useMealsList from "../../../Component/GetMealList";
import useDietList from "../../../Component/GetDietList";
// import { StarSVG } from "../../../Component/SVG";

const SearchSection = ({ values, getData }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  // const [meal, setMeal] = useState([]);
  // const [diet, setDiet] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const mealRef = useRef([]);
  const dietRef = useRef([]);
  const { stateList } = useStateList(null);
  // const catRef = useRef([]);
  const stateRef = useRef("");
  const { mealList } = useMealsList();
  const { dietList } = useDietList();

  const formik = useFormik({
    initialValues: {
      state_ids: [],
      meal: [],
      diet: [],
    },

    validationSchema: Yup.object({
      meal: Yup.array()
        .min(0, `${t("search.validate.food")}`)
        .nullable(),
      state_ids: Yup.array()
        .min(0, `${t("Login.required")}`)
        .nullable(),

      diet: Yup.array()
        .min(0, `${t("search.validate.veg")}`)
        .nullable(),
    }).test("at-least-one", `${t("search.atleastone")}`, function (value) {
      const { meal, state_ids, diet } = value;
      const isMealFilled = meal && meal?.length > 0;
      const isDietFilled = diet && diet?.length > 0;
      const isStateFilled = state_ids && state_ids?.length > 0;

      return isMealFilled || isDietFilled || isStateFilled;
    }),

    onSubmit: async (values) => {
      setLoading(true);

      try
      {
        mealRef.current = values.meal;
        dietRef.current = values.diet;
        stateRef.current = values.state_ids;
        getData &&
          getData(
            0,
            values.meal,
            mealRef.current,
            values.diet,
            dietRef.current,
            values.state_ids,
            stateRef.current,
            "search"
          );
        navigate(`${NavRoutes.TRUCKS}/0`, {
          state: {
            formValues: values,
            type: "search",
          },
        });
      } catch (error)
      {
        console.error("Error fetching trucks:", error);
      } finally
      {
        setLoading(false);
      }
    },
  });

  // const handleMeal = async () => {
  //   try {
  //     let res = await API_GET(API_endpoints.getmealcat);
  //     res?.s && setMeal(res?.r);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleDiet = async () => {
  //   try {
  //     let res = await API_GET(API_endpoints.getdietcat);
  //     res?.s && setDiet(res?.r);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   handleMeal();
  //   handleDiet();
  // }, [i18n?.language]);

  useEffect(() => {
    if (values)
    {
      formik.setValues(values);
    }
  }, [values]);
  return (
    <Box
      sx={{
        width: "100%",
        background:
          location.pathname === NavRoutes.HOME
            ? theme.palette.background.section
            : "transparent",
        py: 3,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          width: "90%",
          maxWidth: "1400px",
          marginX: "auto",
          boxShadow: "0px 0px 20px 0px #17120D14",
          borderRadius: "14px",
          padding: 3,
          my: 3,
          bgcolor: theme.palette.background.section,
        }}
      >
        {/* Left section */}
        <Grid className="removeDefault" item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={formik.touched.meal && Boolean(formik.errors.meal)}
                sx={{
                  color: theme.palette.text.defaultDark,
                  textAlign: "start",
                  borderRadius: "12px",
                }}
              >
                <Autocomplete
                  multiple
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                    "&:active": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                    },
                  }}
                  id="meals"
                  name="meal"
                  options={mealList || []}
                  getOptionLabel={(option) =>
                    i18n.language === "en-US"
                      ? option?.meal_name
                      : option?.german
                  }
                  value={
                    mealList?.filter((m) => formik.values.meal?.includes(m.id)) || []
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "meal",
                      newValue.map((m) => m.id)
                    );
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("search.input1")}
                      error={formik.touched.meal && Boolean(formik.errors.meal)}
                      helperText={formik.touched.meal && formik.errors.meal}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: theme.palette.text.defaultDark,
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.strip,
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: theme.palette.background.strip,
                        },
                        "& .MuiFormHelperText-root": {
                          color: theme.palette.text.error,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "12px",
                            borderColor: `1px solid ${theme.palette.background.strip}`,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={
                          i18n.language === "en-US"
                            ? option?.meal_name
                            : option?.german
                        }
                        {...getTagProps({ index })}
                        sx={{
                          color: theme.palette.text.defaultDark,
                          "& .MuiChip-deleteIcon": {
                            color: "black",
                          },
                        }}
                      />
                    ))
                  }
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        bgcolor: theme.palette.background.main,
                        color: theme.palette.text.strip,
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  ListboxProps={{
                    sx: {
                      bgcolor: theme.palette.background.main,
                      color: theme.palette.text.defaultDark,
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          bgcolor: theme.palette.background.inputBgHover,
                          color: theme.palette.text.active,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            {/* State input */}
            <Grid item xs={12} md={6}>
              <StateAutocomplete
                formik={formik}
                stateList={stateList}
                pagekey={2}
              />
            </Grid>

            <Grid item xs={12}>
              <FormGroup row sx={{ gap: 1 }}>
                {dietList?.length > 0 &&
                  dietList?.map((e, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          id={e?.id.toString()}
                          value={e?.id}
                          checked={formik.values.diet?.includes(Number(e?.id))}
                          onChange={(event) => {
                            const selectedId = Number(event?.target.value);
                            const selectedDiet = [
                              ...(formik.values.diet || []),
                            ];

                            if (event?.target.checked)
                            {
                              selectedDiet.push(selectedId);
                            } else
                            {
                              const index = selectedDiet.indexOf(selectedId);
                              if (index > -1)
                              {
                                selectedDiet.splice(index, 1);
                              }
                            }

                            formik.setFieldValue("diet", selectedDiet);
                          }}
                        />
                      }
                      label={i18n.language === "de" ? e?.german : e?.diet_name}
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  ))}
              </FormGroup>

              {/* Error message for diet */}
              {formik.touched.diet && formik.errors.diet && (
                <Box sx={{ color: "#E06B6B", fontSize: "12px" }}>
                  {formik.errors.diet}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Submit button section */}
        <Grid
          item
          xs={12}
          md={3}
          className="removeDefault"
          container
          justifyContent={{ xs: "center", md: "end" }}
          alignItems={"start"}
          flexDirection={{ xs: "row-reverse", md: "row" }}
          gap={0.5}
        >
          <ButtonComponent
            isAnimate={true}
            label={loading ? <Loader /> : t("search.btn")}
            sx={{
              background:
                location.pathname === NavRoutes.HOME
                  ? theme.palette.background.btn
                  : theme.palette.background.brown,
              color: theme.palette.text.defaultLight,
              width: "200px",
              mt: { xs: 1, md: 0 },
            }}
            onClick={formik.handleSubmit}
          />

          {(formik.values.state_ids?.length > 0 ||
            formik.values.meal?.length > 0 ||
            formik.values.diet?.length > 0) && (
              <ButtonComponent
                isAnimate={true}
                label={t("search.clear")}
                sx={{
                  background: theme.palette.background.card,
                  color: theme.palette.text.brown,
                  border: `1px solid ${theme.palette.text.brown}`,
                  width: "200px",
                  mt: { xs: 1, md: 0 },
                  fontSize: "10px",
                }}
                onClick={() => {
                  navigate(location.pathname, { replace: true, state: {} });
                  formik.resetForm();
                  getData && getData(0, [], [], [], [], [], [], "");
                }}
              />
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchSection;
