import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { English } from "./English";
import { German } from "./German";
const language = localStorage.getItem("i18nextLng") ?? "de";
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: language ,
    debug: true,
    fallbackLng: "de",
    resources: {
      de: German,
      en: English,
    },

    interpolation: { escapeValue: false },
  });

export default i18next;
