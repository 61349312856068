import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import disIcon from "../../../../Assets/images/distance.webp";
import estricIcon from "../../../../Assets/images/estrik.webp";
import ButtonComponent from "../../../../Component/Button/Button";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../../Component/Navbar/NavRoutes";
import { API_endpoints } from "../../../../API/API_Endpoints";
import { useTranslation } from "react-i18next";
import RatingStar from "../../../../Component/Rating";
import premiumTruckLogo from "../../../../Assets/images/p2.webp";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const TruckCard = ({ data, title, openModal, index }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // const { companyId } = useCompanyId();
  const companyid = localStorage.getItem("Fiesta_compID");
  const isOwner = Number(companyid) === Number(data?.company_id);
  const getLabel = (water, mode) => {
    if (water === 1 && mode === "KWH") return t("explore.waterEle");
    else if (water === 1 && mode === "GAS") return t("explore.waterGas");
    else if (water === 0 && mode === "KWH") return t("explore.Ele");
    else if (water === 0 && mode === "GAS") return t("explore.Gas");
  };

  console.log(getLabel(1, 'KWH'))
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: 3,
        boxShadow: 3,
        position: "relative",
        mt: 2,
        display: "flex",
        flexDirection: "column",
        minHeight: "300px",
        backgroundColor: theme.palette.background.main,
        // gap: 0.3,
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: { md: 220 },
          overflow: "hidden",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${data?.image?.length
              ? API_endpoints.baseURL + "/" + data.image[0].image
              : data?.food_truck_image &&
              API_endpoints.baseURL + "/" + data?.food_truck_image[0]?.image
              })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            filter: "blur(5px)",
            zIndex: 0,
          },
        }}
      >
        <img
          src={premiumTruckLogo}
          style={{
            height: "90px",
            width: "90px",
            position: "absolute",
            top: 0,
            right: 0,
            display: data?.subscription?.truck_limit == 1 ? "none" : "block",
            zIndex: 1000,
            transform: "rotate(90deg)",
          }}
          alt=""
        />
        <img
          src={
            index === 0
              ? API_endpoints.baseURL + "/" + data?.image[0]?.image
              : data?.food_truck_image &&
              API_endpoints.baseURL + "/" + data?.food_truck_image[0]?.image
          }
          alt={data?.title || "Truck Image"}
          style={{
            position: "relative", // Keep it positioned in the stacking context
            height: "100%",
            width: "100%",
            objectFit: "contain", // Ensure the image is not distorted
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            zIndex: 1, // Place it above the blurred background
          }}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          px: 2,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 700,
              fontSize: "20px",
            }}
          >
            {index === 0 ? data?.company_name : data?.truck_name}
          </Typography>
          <span>
            {data?.status === -1 && (
              <VisibilityOffIcon style={{ color: "#fd5d29" }} />
            )}
          </span>
        </Box>

        <Box
          sx={{
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            display: "-webkit-box",
          }}
        >
          <RatingStar rating={data?.avg_rating} />
        </Box>
        {/* <Box
          sx={{
            height: {
              xs: "80px",
              sm: "80px",
              md: "80px",
            },
            overflow: "hidden",
            display: data?.about ? "-webkit-box" : "none",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 500,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "17px",
            }}
          >
            {index === 0 ? data?.about : data?.description}
          </Typography>
        </Box> */}
      </Box>

      <Box
        sx={{
          display: index !== 0 ? "flex" : "none",
          flexDirection: { xs: "row", md: "column", lg: "row" },
          gap: 1,
          px: 2,
          backgroundColor: theme.palette.background.main,
          fontWeight: 600,
          justifyContent: "start",
          alignItems: { xs: "start", lg: "center" },
          fontSize: "17px",
          pt: 0.5,
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
           <img src={elecIcon} height={18} width={18} alt="Electricity Icon" /> 
          <Typography
            sx={{ color: theme.palette.text.active, fontWeight: "600" }}
          >
            {data?.electricity_type}
          </Typography>
          {t("myBooking.Electricity")}
        </Box> */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <img src={disIcon} height={18} alt="Distance Icon" />
          <Typography
            sx={{ color: theme.palette.text.active, fontWeight: "600" }}
          >
            {data?.size}
          </Typography>{" "}
          {t("myBooking.Meters")}
        </Box>
      </Box>

      <Box
        sx={{
          // display: title === "Edit" ? "none" : "flex",
          display: index === 0 ? "flex" : "none",
          justifyContent: "start",
          alignItems: "center",
          //   flexDirection: "column",
          px: 2,
          backgroundColor: theme.palette.background.main,
          fontWeight: 500,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.darkOrange,
            fontWeight: 500,
            mb: 0.5,
          }}
        >
          {data?.caterer_type === 1
            ? t("becomePartner.step2.types.type1")
            : data?.caterer_type === 2
              ? t("becomePartner.step2.types.type2")
              : data?.caterer_type === 3
                ? t("becomePartner.step2.types.type3")
                : t("becomePartner.step2.types.type4")}
        </Typography>
      </Box>

      <Box
        sx={{
          // display: title === "Edit" ? "none" : "flex",
          // display: data?.is_water_required ? "flex" : "none",
          visibility: getLabel(data?.is_water_required, data?.operating_mode) ? 'visible' : 'hidden',
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 1,
          px: 2,
          py: 1,
          backgroundColor: theme.palette.background.main,
          fontWeight: 500,
          height: '45px',
        }}
      >
        <img src={estricIcon} height={13} alt="Water & Electricity Icon" />
        <Typography
          sx={{
            color: theme.palette.text.blue,
            fontWeight: "600",
            fontSize: "17px",
          }}
        >

          {getLabel(data?.is_water_required, data?.operating_mode)}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          // display: title === "Edit" ? "flex" : "none",
          visibility: data?.meal?.length > 0 ? "visible" : "hidden",
          justifyContent: "start",
          alignItems: "center",

          //   flexDirection: "column",
          gap: 1,
          px: 2,
          py: 1,
          backgroundColor: theme.palette.background.main,
          fontWeight: 500,
        }}
      >
        <Typography sx={{ color: theme.palette.text.blue, fontWeight: "600" }}>
          {t("explore.meal")}
        </Typography>
      </Box>

      {/* Category Badge */}

      <Box
        className="hideScrollbar"
        sx={{
          visibility: data?.meal?.length > 0 ? "visible" : "hidden",
          justifyContent: "start",
          gap: 1,
          px: 2,
          pb: 2,
          flexWrap: "wrap",
          backgroundColor: theme.palette.background.main,
          height: "110px",
          display: "flex",
          // overflow: "auto",
        }}
      >
        {data?.meal
          ?.filter((x) => x?.status === 1)
          ?.slice(0, 3)
          ?.map((x, i) => (
            <Typography
              key={i}
              sx={{
                backgroundColor: theme.palette.background.label,
                padding: 1,
                borderRadius: 1,
                color: theme.palette.text.label,
                fontWeight: 400,
                height: "fit-content",
              }}
            >
              {i18n.language === "en-US" ? x?.meal_name : x?.german}
            </Typography>
          ))}
      </Box>

      <Box
        className="flexCenter"
        sx={{
          p: 2,
          pt: 0,
          backgroundColor: theme.palette.background.main,
          borderRadius: 3,
        }}
      >
        <Box
          className="flexCenter"
          sx={{
            p: 2,
            pt: 0,
            backgroundColor: theme.palette.background.main,
            borderRadius: 3,
            width: { xs: "100%", sm: "80%", md: "100%" },
            m: "auto",
          }}
        >
          <ButtonComponent
            isAnimate={true}
            onClick={() => {
              if (isOwner)
              {
                if (index === 0)
                {
                  navigate(NavRoutes.MY_COMPANY);
                } else
                {
                  openModal(data);
                }
              } else
              {
                if (index === 0)
                {
                  navigate(`${NavRoutes.COMPANY_DETAILS}/${data?.company_id}`);
                } else
                {
                  navigate(`${NavRoutes.TRUCK_DETAILS}/${data?.id}`, {
                    state: { id: data?.id, company_id: data?.company_id },
                  });
                }
              }
            }}
            label={title}
            sx={{
              width: "100%",
              background: theme.palette.background.btn,
              color: theme.palette.text.defaultLight,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TruckCard;
