export const About_En_trans = {
  title: "About Us",
  subtitle:
     "Fiesta Catering is the go-to place for planning and booking unique food trucks and caterers for any occasion. Let the variety surprise you and make every event a celebration for the senses!",
  list1: "Large selection of food trucks and caterers across Germany",
  list2: "Easy, quick booking and transparent offers",
  list3: "Tailored advice for special requests and event ideas",
  list4: "The platform for networking and acquiring customers for caterers",
  button: "Find Your Caterer",
};

export const About_DE_trans = {
  title: "Wir über uns",
  subtitle:
   "Fiesta Catering ist die erste Adresse, wenn es um die Planung und Buchung von einzigartigen Foodtrucks und Caterern für jeden Anlass geht. Lassen Sie sich von der Vielfalt überraschen und machen Sie jedes Event zu einem Fest für die Sinne!",
  list1: "Große Auswahl an deutschlandweiten Foodtrucks und Caterern",
  list2: "Einfache, schnelle Buchung und transparente Angebote",
  list3: "Maßgeschneiderte Beratung für besondere Wünsche und Eventideen",
  list4: "Die Plattform zur Vernetzung und Kundengewinnung für Caterer",
  button: "Finde deinen Caterer",
};



