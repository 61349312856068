export const FoodTruckScreen_EN_Trans = {
  title: "Caterer",
  noData: "No entries available",
  Company: "Food Truck",
  noMoreData: "No More Data",
};

export const FoodTruckScreen_DE_Trans = {
  title: "Caterer",
  noData: "Keine Einträge vorhanden",
  Company: "Food Truck",
  noMoreData: "Keine weiteren Daten",
};

