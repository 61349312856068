export const Addtruck_EN_Trans = {
  submit: "Submit",
  Cet_Fiesta: "FIESTA CATERING",
  MyFoodTruck: {
    title: "My Food Trucks",
    Cet_Fiesta: "FIESTA CATERING",
    home: "Home",
    addTruck: "Add a Food Truck",
    edit: "Edit",
    noData: "No records found",
    TruckEdit: "Edit Food Truck",
  },
};

export const Addtruck_DE_Trans = {
  submit: "Einreichen",
  Cet_Fiesta: "FIESTA CATERING",
  MyFoodTruck: {
    title: "Meine Food Trucks",
    Cet_Fiesta: "FIESTA CATERING",
    home: "Startseite",
    addTruck: "Einen Food Truck hinzufügen",
    edit: "Bearbeiten",
    noData: "Keine Datensätze gefunden",
    TruckEdit: "Food Truck bearbeiten",
  },
};

