export const response_EN_trans = {
  success: "Success",
  notMatch: "Email and Password does't match",
  login: "Login Succesfully",
  logout: "Logout Succesfully",
  notAuth: "You are not authenticated",
  reset: "Reset password link has been sent to your email.",
  subReq: "Newsletter subscribe mail has been sent to your email.",
  unSubReq: "Newsletter Unsubscribe mail has been sent to your email.",
  invalidCredentials: "Something went wrong.",
  booking:'We have a confirmed booking for these dates.'
};
export const response_DE_trans = {
  success: "Gesendet",
  notMatch: "E-Mail und Passwort stimmen nicht überein",
  login: "Login erfolgreich",
  logout: "Logout erfolgreich",
  notAuth: "Sie sind nicht registriert",
  reset:
    "Der Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet.",
  subReq:
    "Die E-Mail zum Newsletter-Abonnement wurde an Ihre E-Mail-Adresse gesendet.",
  unSubReq:
    "Die E-Mail zum Abbestellen des Newsletters wurde an Ihre E-Mail-Adresse gesendet.",
  invalidCredentials: "Etwas ist während des Deployments schiefgelaufen.",
  booking:'Für diese Termine liegt uns eine bestätigte Buchung vor.'
};
