export const Search_En_trans = {
  validate: {
    food: "Food is required",
    codeType: "Must be a number",
    code: "Postal code is required",
    veg: "At least one option is required",
  },
  input1: "Choose Food Concept",
  input2: "Postal Code",
  input3: "Choose Type of meals",
  input4: "Choose Type of special diets",
  input5: "Gluten-Free",
  btn: "Search",
  atleastone: "at least one",
  clear: 'Deselect'
};

export const Search_DE_Trans = {
  validate: {
    food: "Essen ist erforderlich",
    codeType: "Muss eine Zahl sein",
    code: "Postleitzahl ist erforderlich",
    veg: "Mindestens eine Option ist erforderlich",
  },
  input1: "Food-Konzept wählen",
  input2: "Postleitzahl",
  input3: "Wählen Sie die Art der Mahlzeiten",
  input4: "Wählen Sie die Art der speziellen Diät",
  input5: "Glutenfrei",
  btn: "Suchen",
  atleastone: "mindestens eine",
  clear: 'Auswahl Aufheben '
};
