import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hasToken, setHasToken] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const storedData = localStorage.getItem("Fiesta");
    if (storedData) {
      const { token } = JSON.parse(storedData);
      if (token) {
        setHasToken(true);
      }
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null; 
  }

  if (hasToken) {
    return children;
  }

  localStorage.setItem("redirectPath", location.pathname);

  return <Navigate to="/login" />;
};

export default PrivateRoute;
