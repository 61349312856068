export const Menu_En_trans = {
  menutitle: "Our Food Concepts at a Glance",
  subtitle:
    "It is a long established fact that a reader will be distracted by the readable",
  burgermenu: "Burger",
  pizzamenu: "Pasta",
  icecreammenu: "Sweets & Candy",
  bbqmenu: "Finger Food & Snacks",
};

export const Menu_DE_trans = {
  menutitle: "Unsere Food-Konzepte im Überblick",
  subtitle:
    "Es ist eine allgemein bekannte Tatsache, dass ein Leser abgelenkt wird von den lesbaren Inhalten",
  burgermenu: "Burger",
  pizzamenu: "Pasta",
  icecreammenu: "Sweets & Candy",
  bbqmenu: "Fingerfood & Snacks",
};

