export const MyBooking_EN_trans = {
  title: "My Booking",
  all: "All",
  pending: "Pending",
  cancelled: "Cancelled",
  booknow: "Book Now",
  Electricity: "Electricity",
  Meters: "Meter",
  startDate:"Start Date",
  endDate:"End Date",
  cName:"Caterer Name",
  noData:"No Record found"
};
export const MyBooking_DE_trans = {
  title: "Meine Buchung",
  all: "Alle",
  pending: "Ausstehend",
  cancelled: "Storniert",
  booknow: "Jetzt buchen",
  Electricity: "Elektrizität",
  Meters: "Meter",
  startDate: "Startdatum",
  endDate: "Enddatum",
  cName: "Caterername",
  noData: "Kein Datensatz gefunden"
};

