export const Eventandcat_EN_trans = {
  title: "Event & Catering",
  subTitle: "Search and find your caterer for every occasion and taste!",
  btn: "Find your caterer",
  card1: {
    title: "Coffee Bike",
    content: `Perfect for a successful start or a small break at any event!`,
  },
  card2: {
    title: "Ice Cream Truck",
    content: `A highlight for young and old!`,
  },
  card3: {
    title: "Mexican",
    content: `Mexican cuisine brings zest and fiery flavors to your celebration!`,
  },

  card4: {
    title: "Chimney Cake",
    content: "Adds a special touch to any event.",
  },
  card5: {
    title: "Bowls",
    content: `Colorful, healthy, and varied—the perfect choice for every taste!`,
  },
  card6: {
    title: "Grill",
    content: "Creates delicious BBQ moments and a lively atmosphere!",
  },
};

export const Eventandcat_DE_Trans = {
  title: "Event & Catering",
  subTitle:
    "Suche und finde deinen Caterer für jeden Anlass und nach deinem Geschmack!",
  btn: "Finde einen Food Truck",
  card1: {
    title: "Coffee Bike",
    content:
      "Perfekt für einen gelungenen Start oder eine kleine Pause auf jedem Event!",
  },
  card2: {
    title: "Eiswagen",
    content: "Ein Highlight für Groß und Klein!",
  },
  card3: {
    title: "Mexikanisch",
    content:
      "Die mexikanische Küche bringt Schwung und feurige Aromen auf Ihre Feier!",
  },
  card4: {
    title: "Baumstriezel",
    content: "Verleiht jedem Event eine besondere Note.",
  },
  card5: {
    title: "Bowls",
    content:
      "Bunt, gesund und vielfältig –Für jeden Geschmack die perfekte Auswahl!",
  },
  card6: {
    title: "Grill",
    content: "Sorgt für leckere BBQ- Momente und eine gesellige Stimmung!",
  },
};

