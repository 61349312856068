export const Faq_EN_trans = {
  faq: "FAQs",
  noDAta: "No Data Found",
  customer: "Customers",
  caterer: "Caterers",
};

export const Faq_DE_trans = {
  faq: "Häufig gestellte Fragen",
  noDAta: "Keine Daten gefunden",
  customer: "Kunden",
  caterer: "Caterer",
};
