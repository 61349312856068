import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import sideimg1 from "../../../Assets/images/strip1.webp";
import sideimg2 from "../../../Assets/images/strip2.webp";
import { useTranslation } from "react-i18next";
import EandCCard from "./Cards/eAndCCard";
import mainImg from "../../../Assets/images/home/EandC.webp";
import ButtonComponent from "../../../Component/Button/Button";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "../../../Component/Navbar/NavRoutes";
const cateringData = [1, 2, 3, 4, 5, 6];

const EventandcatSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        paddingY: { xs: 3, sm: 5 },
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%", lg: "50%", xl: "40%" },
          textAlign: "center",
          m: "auto",
          mb: 4, // Margin to separate from image below
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{
            color: theme.palette.text.dark,
            fontSize: { xs: "28px", sm: "32px", md: "40px" },
            // fontWeight: 600,
            mb: 2,
          }}
        >
          {t("eAndC.title")}
          {/* <span className="ampersand">&</span> {t("eAndC.title1")} */}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            mb: 1,
          }}
        >
          {t("eAndC.subTitle")}
        </Typography>
      </Box>

      <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
        <Box
          sx={{
            width: "90%",
            marginX: "auto",
            maxWidth: "1400px",
            px: 2,
          }}
        >
          <Grid container spacing={5} sx={{ alignItems: "flex-start" }}>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "300px", sm: "400px", md: "500px" },
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
                className="flexCenter"
              >
                <img
                  src={mainImg}
                  alt="Event and Catering"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                    maxWidth: "500px",
                  }}
                // className="scaleImg"
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(auto-fit, minmax(200px, 1fr))",
                    md: "repeat(2, 1fr)",
                  },
                  gap: 2,
                }}
              >
                {cateringData.map((x, i) => (
                  <EandCCard data={x} key={i} />
                ))}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
            <img src={sideimg1} alt="Side 1" height={40} />
          </Box>
          <Box sx={{ position: "absolute", left: 0, bottom: "50px" }}>
            <img src={sideimg2} alt="Side 2" height={40} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <ButtonComponent
              type="button"
              label={t("eAndC.btn")}
              isAnimate={true}
              onClick={() => navigate(`${NavRoutes.TRUCKS}/0`)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "55%",
                  md: "55%",
                  lg: "35%",
                  xl: "20%",
                },
                background: theme.palette.background.btn,
                color: theme.palette.text.defaultLight,
              }}
            />
          </Box>
        </Box>
      </ScrollAnimation>
    </Box>
  );
};

export default EventandcatSection;
