import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import trak from "../../../../Assets/images/food-truck 2.webp";

const EandCCard = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box
        // className='cardHover'
        sx={{
          background: theme.palette.background.section,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          textAlign: { xs: "center", sm: "left" },
          p: 2,
          borderRadius: "8px",
          boxShadow: "1px 1px 11px 3px #00000007",
          minHeight: "166px"
        }}
      >
        <Box sx={{ width: "100%" }}>
          <img src={trak} style={{ height: "50px", width: "50px" }} alt="" />
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontSize: { xs: "18px", sm: "18px", md: "20px" },
              fontWeight: 600,
            }}
          >
            {t(`eAndC.card${data}.title`)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.subHead,
              fontSize: { xs: "16px", sm: "16px", md: "16px" },
            }}
          >
            {t(`eAndC.card${data}.content`)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default EandCCard;
