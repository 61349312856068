export const Navbar_EN_Trans = {
  Home: "Home",
  BecomeAPartner: "Become A Partner",
  FoodTrucks: "Caterer",
  AboutUs: "About Us",
  Profile: "Profile",
  ReachUs: "Reach us",
  Login: "Login",
  chat: "Chat",
  Logout: "Logout",
  mytrucks: "My Company",
  faq: "FAQ's",
};



export const Navbar_DE_Trans = {
  Home: "Startseite",
  BecomeAPartner: "Partner werden",
  FoodTrucks: "Caterer",
  AboutUs: "Über uns",
  Profile: "Profil",
  ReachUs: "Kontakt",
  Login: "Login",
  chat: "Chatten",
  Logout: "Ausloggen",
  mytrucks: "Mein Unternehmen",
  faq: "FAQ´s",
};


