import { useEffect, useState } from "react";
import { API_GET } from "../API/config";
import { API_endpoints } from "../API/API_Endpoints";

const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchList = async () => {
    try {
      let res = await API_GET(API_endpoints.getStates);
      if (res?.s) {
        setStateList(res?.r);
      } else {
        // throw new Error("Failed to fetch data");
        console.log(error);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  return { stateList, loading, error };
};

export default useStateList;
